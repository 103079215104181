@use 'sass:map';
@import 'src/styles/themeColors.scss';

@mixin useTheme($theme: default) {
  $core1: map.get($themeColors, $theme, core1) !global;
  $core2: map.get($themeColors, $theme, core2) !global;
  $core3: map.get($themeColors, $theme, core3) !global;
  $core4: map.get($themeColors, $theme, core4) !global;
  $core5: map.get($themeColors, $theme, core5) !global;
  $core6: map.get($themeColors, $theme, core6) !global;
  $core7: map.get($themeColors, $theme, core7) !global;
  $core8: map.get($themeColors, $theme, core8) !global;
  $tint1: map.get($themeColors, $theme, tint1) !global;
  $tint2: map.get($themeColors, $theme, tint2) !global;
  $tint3: map.get($themeColors, $theme, tint3) !global;
  $tint4: map.get($themeColors, $theme, tint4) !global;
  $tint5: map.get($themeColors, $theme, tint5) !global;
  $tint6: map.get($themeColors, $theme, tint6) !global;
  $tint7: map.get($themeColors, $theme, tint7) !global;
  $tint8: map.get($themeColors, $theme, tint8) !global;
  $tint9: map.get($themeColors, $theme, tint9) !global;
  $tint10: map.get($themeColors, $theme, tint10) !global;
  $shade1: map.get($themeColors, $theme, shade1) !global;
  $shade2: map.get($themeColors, $theme, shade2) !global;
  $shade3: map.get($themeColors, $theme, shade3) !global;
  $shade4: map.get($themeColors, $theme, shade4) !global;
  $shade5: map.get($themeColors, $theme, shade5) !global;
  $shade6: map.get($themeColors, $theme, shade6) !global;
  $shade7: map.get($themeColors, $theme, shade7) !global;
  $shade8: map.get($themeColors, $theme, shade8) !global;
  $shade9: map.get($themeColors, $theme, shade9) !global;
  $gray1: map.get($themeColors, $theme, gray1) !global;
  $gray2: map.get($themeColors, $theme, gray2) !global;
  $gray3: map.get($themeColors, $theme, gray3) !global;
  $gray4: map.get($themeColors, $theme, gray4) !global;
  $gray5: map.get($themeColors, $theme, gray5) !global;
  $gray6: map.get($themeColors, $theme, gray6) !global;
  $charcoal: map.get($themeColors, $theme, charcoal) !global;
  $charcoalNew: map.get($themeColors, $theme, charcoalNew) !global;
  $pebbleTint1: map.get($themeColors, $theme, pebbleTint1) !global;
}

@mixin primaryFont($weight: 400) {
  font-family: Graphik, sans-serif;
  font-weight: $weight;
  --webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@mixin secondaryFont($weight: 400) {
  font-family: Simula, serif;
  font-weight: $weight;
  font-variant: lining-nums;
  --webkit-font-smoothing: antialiased;
}

@mixin tertiaryFont($weight: 700) {
  font-family: ABC Monument Grotesk, sans-serif;
  font-weight: $weight;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin tanMonCheriFont() {
  font-family: 'Tan Mon Cheri', sans-serif;
}

@mixin transparentButton() {
  border: none;
  background-color: transparent;
}

@mixin anchorButton() {
  @include transparentButton;
  text-decoration: underline;
  cursor: pointer;
}
