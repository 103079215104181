@import 'src/styles/variables/variables';

@include useTheme();

.ModalDialog {
  transform: translateY(-50%) !important;
  position: relative;
  top: 50%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;

  :global {
    .modal-content {
      position: relative;
      height: 100%;
      border-radius: 4px;
      background-clip: unset;
      border: 0;
    }
  }

  .Content {
    width: 100%;
    overflow-y: auto; 
    height: 100%;
    padding-top: 93px;
    @include max-width(desktop) {
      padding-top: 0;
    }
  }

  @include max-width(desktop) {
    height: 100%;
    margin: 0 auto;
  }

  @include min-width(desktop) {
    margin: 0 auto;
    margin-top: -85px;
    max-width: 952px;
    position: relative;
    width: 952px;
    height: 63.2%;
    max-height: 1028px;
    min-height: 600px;
    &.isLoggedIn {
      height: 55.6%;
    }
  }

  .MobileTop {
    @include min-width(desktop) {
      display: none;
    }
    display: flex;
    justify-content: flex-end;
    height: 93px;

    .CloseButton {
      background-color: transparent;
      border: none;
      outline: none;
      height: 30px;
      width: 30px;
      padding: 0;
      margin: 20px 18px 0 0;
    }
  }

  .Top {
    @include max-width(desktop) {
      display: none;
    }
    display: flex;
    justify-content: flex-end;
    height: 93px;
    position: absolute;
    right: 0;
    top: 0;

    .CloseButton {
      background-color: transparent;
      border: none;
      outline: none;
      height: 30px;
      width: 30px;
      padding: 0;
      margin: 31px 35px 0 0;

      @include max-width(desktop) {
        margin: 20px 18px 0 0;
      }
    }
  }

  .SuccessHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 59px;

    .Thanks {
      @include primaryFont;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;
    }
    
    .H1 {
      @include secondaryFont;
      margin-top: 16px;
      font-size: 34px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0.4px;

      @include max-width(desktop) {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.4px; 
      }
    }

    .H2 {
      margin-top: 16px;
      @include primaryFont;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
      max-width: 430px;
      text-align: center;

      @include max-width(desktop) {
        font-size: 16px;
        max-width: 300px;
        white-space: pre-wrap;
      }
    }
  }

  .Header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ContactUs {
      @include primaryFont;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;
    }
    
    .H1 {
      @include secondaryFont;
      margin-top: 16px;
      font-size: 34px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0.4px;

      @include max-width(desktop) {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.4px; 
      }
    }

    .H2 {
      margin-top: 16px;
      @include primaryFont;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
      max-width: 430px;
      text-align: center;

      @include max-width(desktop) {
        font-size: 16px;
        max-width: 300px;
        white-space: pre-wrap;
      }
    }
  }

  .FormWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;

    .Form {
      width: 377px;
      @include max-width(desktop) {
        width: 343px;
      }
      .FieldGroup {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    
      .Error {
        @include primaryFont;
        color: $core7;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        text-align: left;
        margin: 10px 0 0 0;
        justify-content: left;
      }
    
      .Field {
        label {
          @include primaryFont;
          margin: 0 0 10px;
          display: block;
          color: #222;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
    
        input {
          @include primaryFont;
          width: 100%;
          border: 1px solid $silver;
          border-radius: 4px;
          line-height: 35px;
          padding: 4px 8px;
          outline: none;
          &:focus {
            border: 2px solid $tint3 !important;
          }
          &::placeholder {
            color: #999;
          }
        }

        textarea {
          @include primaryFont;
          height: 150px;
          width: 100%;
          border: 1px solid $silver;
          border-radius: 4px;
          padding: 13px;
          &:focus {
            border: 1.4px solid $tint3;
          }
          &::placeholder {
            color: #999;
          }
        }
      }
    }

    .ButtonWrapper {
      margin: 40px 0 117px 0;
    }
  }
}

.Select {
  @include primaryFont;
}

.Group {
  padding-top: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.OrdersGroupLabel {
  font-size: 12px;
  display: flex;
  gap: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;

  .OrderNumber {
    min-width: 131px;
    @include max-width(desktop) {
      min-width: 112px;
    }
  }

  .OrderTotal {
    min-width: 86px;
  }
}

.OrdersOptions {
  display: flex;
  gap: 9px;
  .OrderNumber {
    min-width: 131px;
    @include max-width(desktop) {
      min-width: 112px;
    }
  }
  .OrderTotal {
    min-width: 86px;
  }
}

.RoomsGroupLabel {
  font-size: 12px;
  display: flex;
  gap: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;

  .RoomName {
    min-width: 218px;
    max-width: 218px;
    @include max-width(desktop) {
      min-width: 184px
    }
  }
}

.RoomsOptions {
  display: flex;
  gap: 34px;
  .RoomName {
    min-width: 218px;
    max-width: 218px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include max-width(desktop) {
      min-width: 184px;
      width: 184px;
    }
  }
}

.Asterisk {
  color: $core7;
}