@import '../MainHeader/common.scss';
$black: #000;
$mint: #b8d7d2;
$breakpoint-desktop: 1024px;

.Messaging {
  background: none;
  border: none;
  position: relative;
  top: 2px;

  svg {
    width: 25px;
    height: auto;
    stroke-width: 1px;
    padding: 2px;
  }

  .isConflicting {
    svg path {
      stroke: $core2;
    }
  }
}

$bubbleSize: 18px;
.NotificationCounter {
  align-items: center;
  background: #3e78f1;
  border-radius: 50%;
  color: $core2;
  display: none;
  font-size: #{$bubbleSize * .55};
  font-weight: 500;
  height: $bubbleSize;
  justify-content: center;
  letter-spacing: 0.025em;
  min-width: $bubbleSize;
  position: absolute;
  right: -5px;
  top: 5px;

  &.HasItems {
    display: flex;
  }
}
