$white: #fff;
$black: #000;
$mint: #b8d7d2;
$breakpoint-desktop: 1024px;
$boxShadowInset: rgba(0, 0, 0, 0.8);
$boxShadow: rgba(0,0,0,0.1);

@import 'src/styles/variables/variables';
@import 'src/styles/mixins/mixins';

.MegaMenu {
  background-color: $white;
  box-shadow: inset 0 5px 10px -10px $boxShadowInset;
  height: calc(100vh - 50px);
  left: 0;
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  top: 0px;
  transform: translateX(-100%);
  transition: transform 0.25s ease;
  width: 100%;

  a {
    text-decoration: none;
  }

  @media (min-width: $breakpoint-desktop) {
    background: none;
    box-shadow: none;
    display: block;
    height: auto;
    left: auto;
    overflow: visible;
    padding: 0;
    position: relative;
    top: auto;
    transform: none;
    width: auto;
  }

  &.isOpen {
    transform: translateX(0);
  }
}

.Header{
  margin-top: 17px;
  color: $black;
  font-family: 'GT Walsheim';
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  outline: none;
}



.wrapperFooter > svg {
  left: -20px;
}
.wrapperFooter > p > svg {
  width: 25px;
}

.wrapperFooter {
  position: relative;
  margin-top: 15px;
  svg{
    position: relative;
  }
  p{
    position: absolute;
    bottom: 0px;
    width: 80%;
    left: 10%;
    top: 10px;
    text-align: center;
  }
}

.wrapperNav{
  padding: 0 20px;
  position: relative;

  flex-grow: 1;
  overflow: auto
}

.imageWrapper{
  height: 210px!important;
  position: relative;
  display: block;
  &:before,&:after
  {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    height: 210px;
    border-radius: 5px;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(23, 23, 23, 0.25), rgba(23, 23, 23, 0.25));
  }
}

.ImageFooter{

  width: 100%;
  margin-top: 15px;
  border-radius: 5px;
  height: 210px;
  position: relative;
  span{
    font-size: 18px!important;
    color: white!important;
    position: absolute;
    width: 50%;
    bottom: 10px;
    left: 10px;
    font-weight: 700;
    font-family: 'GT Walsheim'!important;
  }
}

.ImageTextWrapper{
  position: absolute;
  width: 50%!important;
  z-index: 10;
  top: 25px;
  padding-top: 10px;
  padding-left: 5px;
  left: 10px;
  font-family: 'GT Walsheim'!important;
}

.ImageTextTitle{
  font-size: 22px!important;
  color: white!important;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: normal;
}
.ImageTextContent{
  margin: 0;
  font-size: 13px!important;
  line-height: normal;
  color: white!important;
}

.SectionLinks{
  margin-top: 10px;
}


.LinkDetails{
  color: $black;
  display: block;
  font-family: 'GT Walsheim';
  font-size: 14px;
  padding: 10px 0;
  line-height: 1;
  outline: none;
}

.wrapperLinks{
  margin-top: 20px;
}


.ChevronLeft {
  border: none;
  padding-left: 0;
  background: transparent;
  top: 10px;
  box-shadow: none;
  color: black;
  transition: transform 0.15s ease;
  position: relative;
  @media (min-width: $breakpoint-desktop) {
    display: inline-block;
  }

  svg{
    transform: rotate(90deg);
    margin-right: 5px;
    position: relative;
    bottom: 1px;
  }

  span{
    letter-spacing: 0;
    font-weight: bold;
  }
}

