@import 'src/styles/variables/variables';

@include useTheme();

.Root {
  display: flex;
  background-color: $tint1;
  padding: 10px 20px 31px;
  flex-direction: column;

  @include min-width(medium-desktop) {
    padding: 60px 40px 22px;
  }

  ul,
  li,
  button {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    text-decoration: none;
    background: inherit;
  }

  a {
    display: inline-block;
    border: none;
    text-decoration: none;
    background: inherit;
  }

  p {
    margin-bottom: 0;
  }

  .Tabs {
    margin-bottom: 58px;

    @include min-width(medium-desktop) {
      display: flex;
      margin-bottom: 24px;
    }
  }

  .Tab {
    border-bottom: 1px solid $shade1;
    padding-bottom: 16px;

    @include min-width(medium-desktop) {
      border-bottom: none;
      margin-right: 100px;
    }

    button {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 21px;

      @include min-width(medium-desktop) {
        display: none;
      }
    }
  }

  .TabTitle {
    color: $core4;
    text-align: left;
  }

  .TabBody {
    margin-top: 11px;

    li {
      margin-bottom: 0px;
      transition: visibility 0s, opacity 0.6s ease-in-out;
      visibility: hidden;
      height: 0px;
      opacity: 0;

      &.open {
        margin-bottom: 11px;
        visibility: visible;
        opacity: 1;
        height: auto;
      }

      @include min-width(medium-desktop) {
        margin-bottom: 11px;
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }

    .TabItem {
      p {
        cursor: pointer;
      }
    }
  }
  
  .Icon {
    margin-left: auto;
    svg {
      path {
        fill: $core8;
        stroke: $core8;
      }
    }
  }

  .Logo {
    margin-bottom: 30px;

    svg {
      path {
        fill: $core3;
        stroke: $core3;
      }
    }

    @include min-width(medium-desktop) {
      svg {
        width: 50%;
      }
    }
  }

  .Happiness {
    @include min-width(desktop) {
      margin-left: 100px;
      width: 300px;
    }
  }

  .HappinessDescription {
    margin-top: 11px;
  }
}

.Mobile {
  display: block;
  @include min-width(medium-desktop) {
    display: none;
  }
}

.Desktop {
  display: none;
  @include min-width(medium-desktop) {
    display: block;
  }
}

.HIPLocations {
  @include min-width(medium-desktop) {
    ul {
      columns: 2;
    }
  }
}