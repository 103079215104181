// Base colors
$black: #000000;
$white: #ffffff;
$coral: #f77a64;
$warmGray: #f7f3f0;
$slateGray: #738494;
$mutedGray: #979797;
$gullGray: #a1adb7;
$graySuit: #c6c5cd;
$lightBlue: #CBDFE9;
$royalBlue: #3e78f1;
$studioBlue: #3532E7;
$darkBlue: #304561;
$hoverGray: #3F3F3F;
$silver: #cccccc;
$gray: #838383;
$lightGray: #D8D8D8;
$red: #E84516;
$gallery: #eeeeee;
$linen: #faefe6;
$charcoal: #171717;
$graceCharcoal: #212121;
$graceMint: #b8d7d2;
$hollyBerry: #A41630;
$mint: #B8D7D2;
$mintShade:#30554f;
$selectedMint: #43776f;
$darkMint: #99c0ba;
$darkNeutralGreen: #7aa59e;
$murk: #26443f;
$mintShade6: #63a79c;
$mintShade8: #4d887f;
$mintShade10: #3a665f;
$graceGray: #9B9B9B;
$gray3: #BBBBBB;
$gray4: #B7B7B7;
$mustardYellow: #C2B04A;
$reesesLightGreen: #006950;
$reesesDarkGreen: #00533F;
$deepPeach: #FAC8A9;
$yetAnotherGray: #999999;

// old colors ported from monolith
$santaFe: #b26e4b;
$blueBayoux: #445b70;
$mirage: #1c2233;
$straw: #dbc997;
$pancho: #e0b490;
$mercury: #e6e6e6;
$wildSand: #f6f6f6;
$whiteLinen: #f5e8dd;
$pomegranate: #e84416;
$pirateGold: #c28800;

// Border colors
$colorBorderPrimary: $black;
$colorBorderSecondary: $slateGray;

$colorInputBorder: #eee;

// Font colors
$colorFontPrimary: $black;
$colorFontSecondary: #687989;
$colorFontLinkPrimary: #738494;
$colorFontRust: #B26E4B;

// Background colors
$colorBackgroundWhite: $white;
$colorBackgroundGray: #F6F6F6;
$colorBackgroundPeach: #FAEFE6;

// Icon colors
$colorIconHelp: $slateGray;

$gray6: #6b6b6b;

$theme-colors: (
  danger: $hollyBerry,
  disabled: #687989,
  light: #F7F7F7,
  muted: $mutedGray,
  primary: $black,
);

// app store rating
$ratingGold: #ffb400;

$core_colors: (
  core1: #362B24,
  core2: #FFFFFF,
  core3: #E8E5DE,
  core4: #304561,
  core5: #C2B04A,
  core6: #E0E5F0,
  core7: #ED6B4D,
  core8: #E59400
)
