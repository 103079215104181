@import 'src/styles/variables/variables';

.MegaMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 12px;

  @include min-width(desktop) {
    // Center align content when we have smaller screens
   justify-content: center;
   column-gap: 24px;
  }

  @include min-width(1300px) {
   column-gap: 80px;
  }
}

.SubMenu {
  min-width: 135px;
  &.TwoCols {
    flex: 2 1 auto;
  }
}

.SubMenuTitle {
  display: inline-block;
  text-align: left;
}

.SubMenuLinks {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 13.5rem;
  align-items: stretch;
}

.LinkDetails {
  display: block;
  font-size: 14px;
  padding: 8px 0;
  line-height: 1;
  outline: none;
}

.ImageGroup {
  display: flex;
  column-gap: 16px;
  a {
    max-width: 420px;
  }
}

.Image {
  display: block;

  @include max-width(medium-desktop) {
    // Hide every image except the first on smaller screens
    &:nth-child(n+2) {
      display: none;
    }
  }

  img {
    flex: 1 1 auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    object-fit: cover;
  }
  &:hover,
  &:active,
  &:focus,
  &.active {
    img {
      opacity: 0.5;
    }
  }
}

.ImageText {
  h3 {
    text-align: left;
    margin: 12px 0 6px;
  }
  p {
    text-align: left;
    margin: 0;
  }
}
