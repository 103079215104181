@import 'src/styles/variables/variables';

@include useTheme();

.Copy {
  color: $shade1;
  @include min-width(large-tablet) {
    display: flex;
  }

  .Copyright {
    margin-bottom: 8px;
  }

  .Links {
    @include min-width(large-tablet) {
      margin-left: auto;
      margin-right: 5%;
    }
  }

  a {
    color: inherit;
    display: inline-block;
  }
}

.one-trust-button {
  font-weight: 400 !important;
  background-color: transparent !important;
  border-width: 0 !important;
  color: $shade1 !important;
  font-size: 12px !important;
  display: inline !important;
  padding: 0 !important;
}
