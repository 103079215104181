@import '../MainHeader/common.scss';
$black: #000;
$mint: #b8d7d2;
$breakpoint-desktop: 1024px;

.Cart {
  position: relative;
  top: 2px;

  &.isTransparent.isConflicting:not(.isNavOpen) {
    svg {
      g, path{
        stroke: $core2;
      }
    }
    &.isScrolled {
      svg {
        g, path{
          stroke: $core1;
        }
      }
    }
  }

  svg {
    width: 21px;

    @media (min-width: $breakpoint-desktop) {
      width: 21px;
    }
  }
}

.CartCounter {
  align-items: center;
  background: $core8;
  border-radius: 50%;
  color: $core1;
  display: none;
  font-family: 'GT Walsheim';
  font-size: 8px;
  font-weight: 400;
  height: 16px;
  justify-content: center;
  letter-spacing: 0.025em;
  min-width: 16px;
  padding: 1px 0 0;
  position: absolute;
  right: -10px;
  top: -5px;

  &.HasItems {
    display: flex;
  }
}
