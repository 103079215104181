@import 'src/styles/variables/variables';
@include useTheme();

.HeaderButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: space-between;
  width: 100%;
  padding: 0;

  .Title {
    font-size: 16px;
    line-height: 24px;
    color: $core1;
    text-align: left;
  }

  .Icon {
    svg path {
      stroke: $core1;
      stroke-width: 2px;
    }
  }
}

.AccordionBody {
  display: none;
  transform: scaleY(0);
  transition: all 0.2s linear;

  &.IsOpen {
    display: block;
    transform: scaleY(1);
  }
}
