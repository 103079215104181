@import '../MainHeader/common.scss';

$black: #000;
$white: #fff;
$gallery: #eee;
$breakpoint-desktop: 1024px;
$boxShadow: rgba(0,0,0,0.1);

.Account {
  display: none;
  position: relative;
  transform: translateY(1px);

  @media (min-width: $breakpoint-desktop) {
    display: block;
  }

  &:hover .Dropdown {
    display: flex;
  }

  &.isTransparent.isConflicting {
    .Initials {
      background-color: $core2;
      color: $core1;
    }

    &.isScrolled {
      .Initials {
        background-color: $core1;
        color: $core2;
      }
    }
  }
}

.Initials {
  align-items: center;
  background-color: $black;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 25px;
  height: 25px;
  justify-content: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.Dropdown {
  background: $white;
  box-shadow: 0 5px 8px 0 $boxShadow;
  display: none;
  flex-direction: column;
  position: absolute;
  right: -25px;
  top: 25px;
  width: 230px;
}

.Link {
  color: $core1;
  display: block;
  font-size: 14px;
  font-weight: 100;
  padding: 12px 25px;
  transition: transform 0.25s ease;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    color: $black;
    transform: translateX(5px);
  }
}

.Separator {
  background-color: $gallery;
  height: 1px;
  width: 100%;
}
