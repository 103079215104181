@import '../variables/havenlyBreakpoints';
@mixin min-width($value) {
  @if (map-has-key($havenlyBreakpoints, $value)) {
    @media (min-width: map-get($havenlyBreakpoints, $value)) {
      @content;
    }
  } @else {
    @media (min-width: $value) {
      @content;
    }
  }
}
