@mixin animations($animation, $speed: 0.5s, $fillMode: forwards) {
  animation: $animation $speed $fillMode;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }

  50% {
    opacity: 1;
    transform: scale(1.15);
  }

  65% {
    opacity: 1;
    transform: scale(0.9);
  }

  75% {
    opacity: 1;
    transform: scale(1.05);
  }

  82% {
    opacity: 1;
    transform: scale(0.975);
  }

  90% {
    opacity: 1;
    transform: scale(1.015);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes rotateSlideUpwards {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, -50px) rotateX(-10deg);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.65);
  }

  80% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes pressAndRelease {
  0%,
  100% {
    transform: none;
  }

  40% {
    transform: scale(0.95);
  }
}
