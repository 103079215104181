@use 'sass:map';

// Global Navigation
$navHeight: 55px; // Legacy nav bar
$mobileNavHeight: 64px;
$mainNavHeight: 64px;
$mainNavZIndex: 1040;

// Shop Navigation
$shopNavigationHeight: 56px;


// PDP
$stickyHeaderHeight: 91px;


$navigationHeights: (
  navHeight: 55,
  mainNavHeight: 64,
  mainNavZIndex: 1040,
  shopNavigationHeight: 56,
  stickyHeaderHeight: 91,
);


// export all heights variables to Js/Ts
:export {
  @each $key, $value in $navigationHeights {
    #{$key}: $value;
  }
}
