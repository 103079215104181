@import '../Header.variables.scss';

.LoginSignup {
  display: block;
  font-family: 'GT Walsheim';
  font-size: 14px;
  font-weight: 500;
  margin-right: 58px;


  &.isDesktopOnly {
    display: none;

    @media (min-width: $breakpoint-desktop) {
      display: block;
    }
  }

  a {
    border-bottom: 2px solid transparent;

    color: $black;
    padding: 18px 0;
    transition: border 0.15s ease, padding 0.15s ease;

    &:hover,
    &:active,
    &:focus,
    &.active {
      border-bottom: 2px solid $black;
      color: $black;
      padding-bottom: 15px;
      text-decoration: none;
    }
  }
}

.Pipe {
  padding: 0 5px;
}
