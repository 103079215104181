@import '../Header.variables.scss';

.XContainer {
  position: relative;
  width: 20px;
  height: 20px;
}

.Line {
  background-color: $black;
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.25s ease;
  width: 100%;

  &:nth-child(1) {
    transform: translate(0, 10px) rotate(-45deg)
  }

  &:nth-child(2) {
    transform: translate(0, 10px) rotate(45deg)
  }
}

.Search {
  background:none;
  border: 0;
}

.SearchIcon {
  @media (min-width: $breakpoint-desktop) {
    height: 20px;
    width: 20px;
  }

  path {
    fill: transparent!important;
  }
}
