@import "../../../common.scss";

.ListWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 460px);
  padding: 8px 20px 35px;


  .Header {
    margin-bottom: 8px;
    text-align: left;
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    padding: 0;

    svg  {
      margin-right: 14px;

      path {
        stroke: $core4;
        stroke-width: 1.4;
        width: 6px;
        height: 12px;
      }
    }
  }

  .CategoryChildrenList {
    list-style-type: none;
    margin-bottom: 0;
    padding-inline-start: 0;
    margin-top: 20px;

    .ShopHome {
      height: 44px;

      a {
        color: $core1;
      }
    }

    .MerchGroupCategoryAccordion {

      .AccordionHeader {
        margin-top: 20px;
      }

      .AccordionDivider {
        margin-top: 20px;
      }

      .AccordionIcon {
        svg path {
          stroke: $core8;
          height: 15px;
          width: 15px;
        }
      }

      &.IsHighLighted button > span {
        color: $tint3;
      }

      &.isSelected > button > span {
        color: $core8;
        font-weight: 600;
      }
    }

    .MerchGroupCategoryList {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      .SubCategoryAccordion {
        button {
          margin: 8px 0;
        }
        &.isSelected > button > span {
          color: $core8;
          font-weight: 600;
        }
      }

      .MerchGroupWrapper {
        margin: 8px 0;
      }

      .MerchGroup {
        margin: 5px 0;

        &.IsSubCategory {
          font-weight: 300;
        }

        &.isSelected {
          color: $core8;
          font-weight: 600;
        }
      }

      .CategoryChildren {
        list-style-type: none;
        padding-inline-start: 20px;

        li {
          margin: 10px 0;
        }
      }
    }
  }
}

.ImageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 250px;

  .ImageLabel {
    color: $core1;
    text-align: center;
    line-height: 22px;
    padding-top: 2vh;
  }
}

.CarouselWrapper {
  position: relative;
}

.Carousel {
  position: relative;
  min-height: 360px;
  padding-bottom: $mobileNavHeight;
}

.Pagination {
  position: absolute;
  top: 85%;
  z-index: 10;
  width: 100%;
  display: flex;
  gap: 7px;
  justify-content: center;
}

.Bullet {
  height: 6px;
  width: 6px;
  background: $core4;
  opacity: 0.4;
  display: inline-block;
  border-radius: 50%;
}

.BulletActive {
  opacity: 1;
}

