@import 'src/styles/variables/variables';
@import 'src/styles/z-indices.scss';

$boxShadow: rgba(0, 0, 0, 0.1);

.NoScroll {
  overflow: hidden;

  @include min-width(desktop) {
    overflow: initial;
  }
}

.Header {
  align-items: center;
  background-color: $white;
  box-shadow: 0 2px 8px 0 $boxShadow;
  display: flex;
  height: 50px;
  padding: 0 14px;
  position: relative;
  transition: background-color 0.25s ease,
  transform 0.25s ease,
  box-shadow 0.25s ease;
  width: 100%;
  z-index: 2001;

  @include min-width(desktop) {
    padding: 0 40px;

    &.isHidden {
      transform: translateY(-100%);
    }
  }

  &.isTransparent {
    background-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: $white;
      box-shadow: 0 2px 8px 0 $boxShadow;
    }

    &.isScrolled {
      background-color: $white;
      box-shadow: 0 2px 8px 0 $boxShadow;
      transition: background-color 0.25s ease 0.25s,
      transform 0.25s ease,
      box-shadow 0.25s ease 0.25s;
    }
  }

  &.isOpaque {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 2px 8px 0 $boxShadow;;

    &:hover {
      background-color: $white;
      box-shadow: 0 2px 8px 0 $boxShadow;
    }

    &.isScrolled {
      background-color: $white;
      box-shadow: 0 2px 8px 0 $boxShadow;
      transition: background-color 0.25s ease 0.25s,
      transform 0.25s ease,
      box-shadow 0.25s ease 0.25s;
    }
  }

  &.isFixed {
    position: fixed;
  }

  &.isHiddenMobile {
    display: none;

    @include min-width(desktop) {
      display: flex;
    }
  }

  &.hasBanner {
    top: 45px;
  }
}

.ToggleMenu {
  cursor: pointer;
  display: block;
  height: 20px;
  overflow: hidden;
  position: relative;
  width: 25px;

  @include min-width(desktop) {
    display: none;
  }

  .Line {
    background-color: $black;
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.25s ease;
    width: 100%;

    &:nth-child(1) {
      transform: translateY(0)
    }

    &:nth-child(2) {
      transform: translateY(9px)
    }

    &:nth-child(3) {
      transform: translateY(19px)
    }
  }

  &.isOpen .Line {
    &:nth-child(1) {
      transform: translate(0, 10px) rotate(-45deg)
    }

    &:nth-child(2) {
      transform: translateX(-40px)
    }

    &:nth-child(3) {
      transform: translate(0, 10px) rotate(45deg)
    }
  }
}

.Center {
  margin: auto;
}

.MobileOnly {
  @include min-width(desktop) {
    display: none !important;
  }
}

.HideMobile {
  @include max-width(desktop) {
    display: none !important;
  }
}

.RightElementMargin {
  margin-right: 18px;
}

.LoggedInSearchMobile {
  margin-left: 12px;

  @include min-width(desktop) {
    display: none !important;
  }
}

.LoggedInSearchDesktop {
  margin-left: 18px;

  @include max-width(desktop) {
    display: none !important;
  }
}
