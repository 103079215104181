@import 'src/styles/mixins/mixins';

$black: #000;
$white: #fff;
$boxShadowInset: rgba(0, 0, 0, 0.8);
$boxShadow: rgba(0,0,0,0.1);

.Link {
  @include font('GT Walsheim', 100);

  @include min-width(desktop) {
    display: none;
  }

  border-bottom: 1px solid $mint;
  color: $black;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  outline: none;
  padding: 25px 0;
  position: relative;
  width: 100%;

  .LinkDropdownTitle {
    color: $black;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    outline: none;
  }
}

.Chevron {
  display: none;
  margin-left: 5px;
  transition: transform 0.15s ease;
}

.SubNav {
  .Link:active & {
    display: block;
  }
}

.TaxonomyCategoryMenu {
  @include font('ABC Monument Grotesk', 400);
  display: block;
  font-size: 16px;
  font-weight: 100;
  padding-top: 20px;
  width: 100%;
  cursor: pointer;
}

.TaxonomyCategoryLink {
  @include font('ABC Monument Grotesk', 400);
  display: block;
  font-size: 15px;
  font-weight: 100;
  padding-top: 20px;
  padding-left: 20px;
  width: 100%;

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
}

.TaxonomyTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
}

.Active {
  color: #E59400;
}

.PlusIcon {
  display: flex;
  margin-top: -8px;

  @include min-width(tablet) {
    margin-right: 10px;
  }

  & > svg {
    width: 26px;
    height: 26px;
    fill: rgba(black, 0.6);
    stroke: rgba(black, 0.6);
  }
}
