@import 'src/styles/variables/variables';

@include useTheme();

.FollowUs {
  margin-bottom: 20px;
  border-bottom: 1px solid $shade1;
  padding-bottom: 16px;

  @include min-width(large-tablet) {
    border-bottom: none;
  }

  p {
    color: $core4;
    text-align: left;
    margin-top: 21px;

    @include min-width(large-tablet) {
      margin-top: 0;
    }
  }

  .Body {
    display: flex;
    margin-top: 11px;

    li {
      margin-right: 29px;
    }
  }
}
