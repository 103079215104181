@import 'src/styles/variables/variables';

$boxShadowInset: rgba(0, 0, 0, 0.8);
$boxShadow: rgba(0,0,0,0.1);

.Nav {
  background-color: $white;
  box-shadow: inset 0 5px 10px -10px $boxShadowInset;
  height: calc(100vh - 50px);
  left: 0;
  overflow: auto;
  padding: 0 20px 40px;
  position: absolute;
  top: 50px;
  transform: translateX(-100%);
  transition: transform 0.25s ease;
  width: 100%;

  a {
    text-decoration: none;
  }

  @include min-width(desktop) {
    background: none;
    box-shadow: none;
    display: block;
    height: auto;
    left: auto;
    overflow: visible;
    padding: 0;
    position: relative;
    top: auto;
    transform: none;
    width: auto;
  }

  &.isOpen {
    transform: translateX(0);
  }
}

.WrapperMegaMenu{
  display: inline-block;

  @include max-width(desktop) {
    width: 100%;
    span {
      width: 100%;
    }
  }

  > button {
    border: none;
    text-align: left;
    background: 0;
    border-bottom: 1px solid #b8d7d2;
  }
}

.Link {
  border-bottom: 1px solid $mint;
  color: $black;

  @include min-width(desktop) {
    &.isScrolled {
      color: $white;
      transition: color 0.25s;

      &:hover,
      &:focus,
      &:active {
        color: $white;
      }
    }
  }

  display: inline-block;
  font-family: 'GT Walsheim';
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  outline: none;
  padding: 25px 0;
  position: relative; // need to position this so child menu can be absolutely positioned
  width: 100%;

  .LinkDropdownTitle {
    color: $black;
    font-family: 'GT Walsheim';
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }

    @include min-width(desktop) {
      font-size: 14px;
      letter-spacing: 0.3px;
    }
  }

  @include min-width(desktop) {
    border-bottom: 2px solid transparent;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 18px 0 17px;
    transition: border 0.15s ease, padding 0.15s ease;
    width: auto;

    &:not(:last-child) {
      margin-right: 40px;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:not(.DropdownHeader) {
        border-bottom: 2px solid $black;
        padding-bottom: 16px;
      }

      &.isScrolled {
        border-bottom: 2px solid $white;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $black;
  }
}

.Link:hover {
  .StarsNav {
    display: initial;
  }
}

.SubLink {
  display: block;
  font-family: 'GT Walsheim';
  font-size: 16px;
  font-weight: 100;
  padding-top: 20px;
  width: 100%;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $black;
    text-decoration: underline;
  }

  @include min-width(desktop) {
    color: $black;
    display: block;
    font-family: 'GT Walsheim';
    font-size: 14px;
    font-weight: 100;
    padding: 12px 25px;
    transition: transform 0.25s ease;
    width: 100%;

    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $black;
      text-decoration: none;
      transform: translateX(5px);
    }
  }
}

.SubNav {
  display: none;

  .Link:hover &,
  .Link:focus &,
  .Link:active & {
    display: block;
  }

  @include min-width(desktop) {
    background: $white;
    box-shadow: 0 5px 8px 0 $boxShadow;
    display: none;
    left: 0;
    position: absolute;
    top: 50px;
    width: 230px;
  }
}

.StarsNav {
  position: absolute;
  bottom: 3px;
  transition: 0.2s ease-in-out;
}

.WrapperMegaMenuDesktop:hover > div {
  display: block!important;
}

.WrapperMegaMenuDesktop:hover .StarsNav {
  display: initial!important;
  transform: scale(1);
}

.WrapperMegaMenuDesktop:not(:hover) .StarsNav {
  transform: scale(0.7);
}

.Chevron {
  display: none;
  margin-left: 5px;
  transition: transform 0.15s ease;

  @include min-width(desktop) {
    display: inline-block;
  }
}

.DropdownHeader:hover .Chevron {
  transform: rotate(180deg);
}

.MobilePostNavItem {
  color: $black;
  display: block;
  font-family: 'GT Walsheim';
  font-size: 14px;
  font-weight: 100;
  padding: 25px 0 0;

  @include min-width(desktop) {
    display: none;
  }

  a {
    &:hover,
    &:active,
    &:focus {
      color: $black;
      text-decoration: underline;
    }
  }
}

.Pipe {
  padding: 0 10px;
}
