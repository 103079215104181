@mixin font($font: sans-serif, $weight: normal) {
  $font-family: '';

  @if ($font == sans-serif) {
    @if ($weight == light) {
      font-family: Sailec-Light, sans-serif;
    }

    @if ($weight == normal) {
      font-family: Sailec-Regular, sans-serif;
    }

    @if ($weight == medium) {
      font-family: Sailec-Medium, sans-serif;
    }

    @if ($weight == bold) {
      font-family: Sailec-Bold, sans-serif;
    }

    // overriding some global styles
    font-weight: normal !important;
  }

  @if ($font == serif) {
    font-family: 'Surveyor SSm A', 'Surveyor SSm B', serif;

    @if ($weight == normal) {
      font-weight: 300 !important;
    }

    @if ($weight == bold) {
      font-weight: 500 !important;
    }
  }

  @if ($font != sans-serif and $font != serif) {
    font-family: $font;
    font-weight: $weight !important;
  }
}

// use font-smoothing(true) to improve how light text renders on dark backgrounds in some browsers
@mixin font-smoothing($on: true) {
  @if ($on) {
  	-moz-osx-font-smoothing: grayscale !important;
  	-webkit-font-smoothing: antialiased !important;
  } @else {
  	-moz-osx-font-smoothing: auto !important;
  	-webkit-font-smoothing: subpixel-antialiased !important;
  }
}

