@import '../Header.variables.scss';

$almostBlack: #212121;
$boxShadow: rgba(0, 0, 0, 0.6);

.Button {
  align-items: center;
  background-color: $almostBlack;
  border-radius: 28px;
  color: $white;
  display: flex;
  font-family: 'GT Walsheim';
  font-size: 14px;
  height: 38px;
  justify-content: center;
  letter-spacing: 0.3px;
  padding: 0 20px;
  transition: box-shadow 0.15s ease;

  &.isDesktopOnly {
    display: none;

    @media (min-width: $breakpoint-desktop) {
      display: flex;
    }
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 2px 4px $boxShadow;
    color: $white;
    text-decoration: none;
  }
}
