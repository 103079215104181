$white: #fff;
$black: #000;
$mint: #b8d7d2;
$black2: rgba(23, 23, 23, 0.25);
$breakpoint-desktop: 1024px;
$boxShadowInset: rgba(0, 0, 0, 0.8);
$boxShadow: rgba(0,0,0,0.1);

@import 'src/styles/variables/variables';
@import 'src/styles/mixins/mixins';


.wrapperFooter > span {
  left: -20px;
  position: relative;
}

.wrapperFooter {
  position: absolute;
  bottom: -10px;
  width: 100%;
  margin-top: 15px;

  span {
    svg {
      position: relative;
    }
  }

  p {
    position: absolute;
    bottom: 0;
    color: $black;
    width: 100%;
    text-align: center;

    span {
      svg {
        position: relative;
        left: 10px;
      }
    }
  }
}


.ImageRight {
  width: auto;
  border-radius: 5px;
  left: -50px;
  min-width: 72%;
  height: 280px;
  position: relative;

  > span {
    font-size: 18px!important;
    color: $white!important;
    position: absolute;
    width: 60%;
    top: 26px;
    left: 23px;
    font-weight: 700;
    font-family: 'GT Walsheim'!important;
  }
}

.SectionLinks {
  margin-top: 10px;
}

.LinkDetails {
  color: $black;
  display: block;
  font-family: 'GT Walsheim';
  font-size: 14px;
  padding: 10px 0;
  line-height: 1;
  outline: none;
  transition: transform 0.25s ease;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $black;
    text-decoration: none;
    transform: translateX(5px);
  }
}

.wrapperLinks {
  margin-top: 20px;
}

.WrapperMegaMenuDesktop:hover .hidden-child {
  display: block;
}

.MegaMenuDesktopContainer {
  display: none;
  padding-top: 30px;
  width: 700px;
  top: 51px;
  position: absolute;
  right: -40px;
}

.MegaMenuDesktop {
  display: block;

  @media (min-width: $breakpoint-desktop) {
    background: $white;
    box-shadow: 0 18px 27px $black2;
    border-radius: 8px;
    max-height: 450px;
    position: fixed;
    min-height: 440px;
    overflow: hidden;
    /* margin: auto; */
    max-width: 1500px;
    width: calc(99vw - 80px);
    left: 40px;
  }
  @media (min-width: 1580px) {
    max-width: 1400px;
    width: 92vw;
    left: calc((100vw - 1400px)/2);
  }
}

.FirstColumn {
  display: inline-block;
  float: left;
  height: 440px;
  width: 20%;

  .Title {
    margin-top: 43px;
    margin-left: 5vw;
    display: block;
    font-family: 'GT Walsheim';
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  }

  .FirstColumnLinks {
    display: inline-block;
    margin-top: 20px;
    margin-left: 5vw;
  }
}

.SecondColumn {
  float: left;
  display: inline-block;
  width: 20%;
  margin-top: 43px;

  .Title {
    display: block;
    font-family: 'GT Walsheim';
    font-weight: bold;
    font-size: 20px;

    line-height: 27px;
  }

  .SectionLinks {
    margin-top: 20px;
  }

  a {
    margin-left: 3.5vw;
  }

  span {
    margin-left: 3.5vw;
  }

}

.ThirdColumn {
  display: inline-block;
  position: relative;
  height: 440px;
  float: left;
  width: 20%;
  margin-top: 43px;

  .Title {
    display: block;
    font-family: 'GT Walsheim';
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  }

  .SectionLinks {
    margin-top: 20px;
  }

  a {
    margin-left: 2vw;
  }

  span {
    margin-left: 2vw;
  }
}

.FourthColumn {
  display: inline-block;
  position: relative;
  height: 440px;
  float: left;
  margin-left: 3%;
  width: 37%;
  &:hover,
  &:active,
  &:focus,
  &.active {
    svg{
      color: $black;
      text-decoration: none;
      transform: translateX(5px);
    }
  }

}

.Stars {
  display: initial!important;
  svg {
    width: 30px;
  }
}

.ImageTextTitle{
  font-size: 20px!important;
  color: white!important;
  font-weight: 700;
  margin: 0;
}

.ImageTextContent{
  margin: 8px 0;
  font-size: 15px!important;
  color: white!important;
}

.WrapperImageGroup{
  display: block;
  max-width: 72%;
  width: 360px;
  border-radius: 10px;
  height: 280px;
  overflow: hidden;
  margin-top: 43px;
  text-align: center;

  &:after
  {
    content: '';
    position: absolute;
    top: 43px;
    left: 0;
    border-radius: 10px;
    width: 360px;
    height: 280px;
    max-width: 72%;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(23, 23, 23, 0.25), rgba(23, 23, 23, 0.25));
  }
}


.NextCircle {
  position: absolute;
  top: 57px;
  z-index: 3;
  width: 250px;
  text-align: left;
  left: 15px;
}

.NextCircle > svg{
  position: relative;
  top: 4px;
  display: block;
  transition: transform 0.25s ease;
}
