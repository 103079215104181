$breakpoint-desktop: 1024px;

.Logo {
  display: block;
  margin: auto;
  width: 135px;

  span {
    height: 100%;
  }

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.CustomerLogo {
  padding-left: 35px;

  @media (min-width: $breakpoint-desktop) {
    margin: 0;
    padding: 0;
    width: 172px;
  }
}

.LoggedOutLogo {
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;

    @media (min-width: $breakpoint-desktop) {
      width: 214px;
    }
  }
}
